@import '../../../styles/customMediaQueries.css';
.customFilters {
  padding: 10px 0;
  & .filterBlock {
    & > div {
      & > div {
        height: fit-content;
      }
    }
    &:not(:last-child) {
      margin-bottom: 32px;
      /* padding-bottom: 24px; 
      border-bottom: solid 1px var(--colorGrey100);*/
    }
    & > h2 {
      font-size: 13px;
      font-weight: var(--fontWeightSemiBold);
      line-height: 130%;
      color: var(--colorGrey400);
      margin: 0 0 15px 0;
      padding: 0;
      text-transform: uppercase;
    }
    & > select {
      color: var(--colorGrey800);
      font-size: 14px;
      line-height: 100%;
      min-height: 40px;
      height: 40px;
      box-shadow: none;
      border-color: var(--colorGrey100);
      cursor: pointer;
      &:hover {
        border-color: var(--colorGrey200);
      }
    }
  }
  & .options {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer !important;
    margin-bottom: 15px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    & > input {
      width: 18px;
      height: 18px;
      box-shadow: none;
      cursor: pointer !important;
    }
    & > span {
      font-size: 14px;
      line-height: 100%;
      color: var(--colorGrey600);
      font-weight: var(--fontWeightRegular);
    }
  }
}

.clear {
  cursor: pointer;
  font-size: 12px;
  text-align: end;
  margin: 0;
  color: #ee8b51;
  font-weight: 700;
  text-decoration: underline;
}

.clearButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--marketplaceColor);
  border: solid 1px var(--marketplaceColor);
  transition: var(--transitionStyle);
  padding: 10px 20px;
  margin-top: 24px;
  width: 100%;
  border-radius: 4px;
  & > a {
    color: white !important;
    text-decoration: none;
  }
}
