@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCardsMapVariant {
  padding: 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 14px;
  width: 100%;

  @media (--viewportLarge) {
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.listingCards {
  padding: 0;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;

  @media (min-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportSLarge) {
    grid-template-columns: repeat(2, 1fr);
  }
  /*
  @media (--viewportMedium) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 900px) {
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLargeWithPaddings) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(4, 1fr);
  } */
}

.listingCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  /* @media (--viewportLargeWithPaddings) {
    flex-direction: row;
  } */
  &:last-child {
    margin-bottom: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}

.skeletons {
  margin-bottom: 20px;
}
