@import '../../../styles/customMediaQueries.css';

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 0px 10px;
  font-size: 13px;
  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  & > h2 {
    font-size: 16px;
    color: var(--colorBlack);
    font-weight: var(--fontWeightMedium);
    margin: 0 0 20px 0;
    line-height: 120%;
    padding: 0;
  }
}

.resetAllFiltersButton {
  composes: h5 from global;
  font-weight: var(--fontWeightRegular);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0;
  padding: 10px 20px;
  line-height: 120%;
  font-size: 16px;
  background-color: var(--colorWhite);
  display: inline-block;
  border: solid 1px var(--colorGrey100);
  border-radius: 4px;

  /* Override button styles */
  outline: none;
  text-align: left;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
  }
}
