@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.searchPageWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }

  & .searchPageSidebar {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
    border: solid 1px var(--colorGrey100);
    background-color: var(--colorWhite);

    @media (--viewportMedium) {
      /* overflow: hidden; */
      max-width: 280px;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    @media (--viewportLarge) {
      max-width: 300px;
    }

    & .siderBarHead {
      background-color: hsl(240deg 17.05% 97.73%);
      color: var(--colorBlack);
      font-size: 18px;
      line-height: 100%;
      margin: 0;
      padding: 14px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & .sidebarFilters {
      display: none;

      @media (--viewportMedium) {
        display: block;
      }
    }
  }

  & .searchPageMainContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (--viewportMedium) {
      padding: 0 0 0 30px;
      width: calc(100% - 280px);
    }

    @media (--viewportLarge) {
      width: calc(100% - 300px);
    }

    & .listingsFoundSec {
    }
  }
}

.container {
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 72px 0;
  }

  & .shape1 {
    position: fixed;
    left: 0px;
    top: 70px;
    opacity: 1;
    text-align: left;
    z-index: 0;
    display: none;
    height: 100vh;

    @media (--viewportMedium) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    & > img {
      max-width: 40%;
    }
  }

  & .shape2 {
    position: fixed;
    right: 0px;
    top: 70px;
    opacity: 1;
    text-align: right;
    z-index: 0;
    display: none;
    height: 100vh;

    @media (--viewportMedium) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }

    & > img {
      max-width: 40%;
    }
  }

  & .shape3 {
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
    opacity: 1;
    text-align: left;
    z-index: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }

    & > img {
      max-width: 40%;
    }
  }

  & .shape4 {
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
    opacity: 1;
    text-align: right;
    z-index: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }

    & > img {
      max-width: 40%;
    }
  }

  & .shape5 {
    position: fixed;
    left: 0px;
    bottom: 0px;
    opacity: 1;
    text-align: left;
    z-index: 0;
    display: none;
    height: 100vh;

    @media (--viewportMedium) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }

    & > img {
      max-width: 40%;
    }
  }

  & .shape6 {
    position: fixed;
    right: 0px;
    bottom: 0px;
    opacity: 1;
    text-align: right;
    z-index: 0;
    display: none;
    height: 100vh;

    @media (--viewportMedium) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    & > img {
      max-width: 40%;
    }
  }
}

.contentHeadRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    & > div {
      & > div {
        left: auto !important;
        right: 0;
      }
    }
  }
}

.searchResultContainer {
  max-width: 1280px;
  margin: 0px auto;
  width: 100%;
  padding: 30px 24px 0;
  position: relative;
  z-index: 1;
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }

  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }

  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}

.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }

  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}

.error {
  color: var(--colorFail);
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 36px 0 36px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
}

.searchResultSummary {
  composes: textSmall from global;
}

.sortyByWrapper {
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: center;
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }

  & .resultsFound {
    font-size: 20px;
    line-height: 120%;
    color: var(--colorBlack);
    font-weight: var(--fontWeightSemiBold);
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.searchPageSidebarMobile {
  display: none;
}

.searchResultContainerMobile {
  display: flex;
  justify-content: space-between;
}

.filtersIcon {
  position: relative;
  top: 12px;
}
